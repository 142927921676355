html,
body {
    min-height: 100%;
}

#__next {
    height: 100%;
}

code {
    font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
    padding: 0.1rem 0.4rem;
    font-size: 90%;
    color: #00a0ff;
    border-radius: 4px;
    background-color: rgba(0, 160, 255, 0.08);
}

code[class*='language-'] {
    padding: 0;
}

/* Mui Tiptap */
.MuiTiptap-ControlledBubbleMenu-root {
    z-index: 1400 !important;
}
.MuiTiptap-RichTextContent-root{
    min-height: 100px;
}
